var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"transportationForm"},[_c('div',{staticClass:"row mx-0 bg-white rounded p-3"},[(!_vm.isReadOnly)?_c('div',{staticClass:"col-12 col-md mb-2 mb-md-0 border-right"},[_c('validation-observer',{ref:"transportationAddForm"},[_c('validation-provider',{attrs:{"rules":{
              required: _vm.model.selectedTransportationServiceTypes.length == 0,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"color-gray font-16 font-weight-normal mb-2"},[_vm._v(" Servicio de Transportación: ")]),_c('custom-drop-down',{attrs:{"clearable":false,"custom-error-msg":"Favor seleccione al menos un servicio.","error":_vm.transportationServiceTypeError(errors[0]),"fieldkey":"id","fieldname":"name","options":_vm.getAvailableTransportationServiceTypes,"placeholder":"Seleccione"},model:{value:(_vm.model.transportationServiceType),callback:function ($$v) {_vm.$set(_vm.model, "transportationServiceType", $$v)},expression:"model.transportationServiceType"}})]}}],null,false,4029208587)}),_c('div',{staticClass:"text-right"},[_c('action-button',{attrs:{"i":"fas fa-file-plus","text":"Añadir","variant":"success mt-3 pl-3 pr-3 btn-block-xs-only"},on:{"click":function($event){return _vm.addItem(_vm.model.transportationServiceType)}}})],1)],1)],1):_vm._e(),_c('div',{staticClass:"col"},[_c('div',{staticClass:"color-gray font-16 font-weight-normal mb-2"},[_vm._v(" Servicio(s) de Transportación Seleccionado(s): ")]),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.model.selectedTransportationServiceTypes),function(item,index){return _c('div',{key:("row-" + index),staticClass:"chip"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.name))]),(!_vm.isReadOnly)?_c('em',{staticClass:"far fa-lg fa-times-circle color-red cursor-pointer",on:{"click":function($event){return _vm.removeItem(index)}}}):_vm._e()])}),0)])]),_c('div',{staticClass:"row mx-0 bg-white rounded p-3 mt-3"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"d-md-flex align-items-center border-bottom px-3 mb-4"},[_c('div',{staticClass:"flex-grow-1 color-black font-16"},[_vm._v(" ¿Cuándo se completó la solicitud de beca de transportación? ")]),_c('custom-date-picker',{staticClass:"mb-2 mt-2 mt-md-0",attrs:{"custom-error-msg":"Favor seleccione la fecha.","disabled":_vm.isReadOnly,"error":errors[0],"placeholder":"DD/MM/YYYY"},model:{value:(_vm.model.transportationGrantRequestedDate),callback:function ($$v) {_vm.$set(_vm.model, "transportationGrantRequestedDate", $$v)},expression:"model.transportationGrantRequestedDate"}})],1)]}}])}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"d-md-flex align-items-center border-bottom px-3 mb-4"},[_c('div',{staticClass:"flex-grow-1 color-black font-16"},[_vm._v(" ¿Cuándo se completó la solicitud de transportación? ")]),_c('custom-date-picker',{staticClass:"mb-2 mt-2 mt-md-0",attrs:{"custom-error-msg":"Favor seleccione la fecha.","disabled":_vm.isReadOnly,"error":errors[0],"placeholder":"DD/MM/YYYY"},model:{value:(_vm.model.transportationRequestedDate),callback:function ($$v) {_vm.$set(_vm.model, "transportationRequestedDate", $$v)},expression:"model.transportationRequestedDate"}})],1)]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }