<template>
  <div>
    <div v-if="items.length" class="d-flex align-items-center mb-3 ml-n3">
      <em class="fas fa-history color-orange mr-2 font-18" />
      <div class="color-secondary-13 font-weight-bold font-16">
        Historial de Contratos
      </div>
    </div>
    <div
      v-for="(item, index) in items"
      :key="`contract-item-${index}`"
      class="row bg-secondary-3 rounded mb-3 p-3"
    >
      <div class="col">
        <div class="color-gray font-14">Año Fiscal:</div>
        <span class="color-black font-16">{{ item.academicYear }}</span>
      </div>
      <div class="col">
        <div class="color-gray font-14">Número de Contrato:</div>
        <span class="color-black font-16">{{ item.contractNumber }}</span>
      </div>
      <div class="col">
        <div class="color-gray font-14">Estatus:</div>
        <span class="color-black font-16">{{ item.statusName }}</span>
      </div>
      <div class="col text-right">
        <action-button
          i="fas fa-print"
          variant="outline-success"
          @click="$emit('on-download-contract', item)"
        />
      </div>
    </div>
    <div
      v-if="!items.length"
      class="color-secondary-14 font-16 d-flex align-items-center justify-content-center py-3"
    >
      No hay información disponible.
    </div>
  </div>
</template>

<script>
import ActionButton from 'ActionButton';
export default {
  name: 'ContractHistory',
  components: {
    ActionButton,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
