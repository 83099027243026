var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"barrierForm"},[_vm._l((_vm.questions),function(question,idx){return _c('div',{key:("question-" + idx),staticClass:"mb-3"},[(_vm.showChildQuestion(question.id))?_c('ordered-header-card',{staticClass:"ordered-header-card__extended",class:{ disabled: _vm.isReadOnly },attrs:{"order":idx + 1}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(question.text)+" ")]),_c('div',{attrs:{"slot":"actions"},slot:"actions"},[_c('div',{staticClass:"d-flex align-items-center"},[(question.radioOptions != null)?_c('validation-provider',{attrs:{"rules":{ required: question.model.radioAnswer == null }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-radio',{staticClass:"custom-question__extended mt-4",class:{ disabled: _vm.isReadOnly },attrs:{"change-event":function (item) {
                    _vm.onChangeYesNo(item, idx);
                  },"custom-error-msg":"Favor seleccione si o no.","disabled":_vm.isReadOnly,"error":!!errors[0],"is-question-list":"","name":("barrierQuestion-" + idx),"options":question.radioOptions}})]}}],null,true)}):_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('custom-date-picker',{attrs:{"class-container":"py-3","custom-error-msg":"Favor seleccione la fecha","disabled":_vm.isReadOnly,"error":errors[0],"max-date":new Date(),"placeholder":"DD/MM/YYYY"},model:{value:(question.model.date),callback:function ($$v) {_vm.$set(question.model, "date", $$v)},expression:"question.model.date"}})]}}],null,true)})],1)])]):_vm._e()],1)}),(_vm.showChildQuestion(3))?_c('div',{staticClass:"d-block mt-4"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('custom-text-area',{staticClass:"equipment__text-area",attrs:{"id":"comment","count-classes":['d-block', 'small-counter'],"custom-error-msg":"Favor escriba las barreras arquitectónica que solicita ser removida.","disabled":_vm.isReadOnly,"error":errors[0],"label":"¿Qué tipo de barrera arquitectónica solicita sea removida?","maxlength":3000,"name":"comment","show-char-count":""},model:{value:(_vm.model.serviceDescription),callback:function ($$v) {_vm.$set(_vm.model, "serviceDescription", $$v)},expression:"model.serviceDescription"}})]}}],null,false,2856321592)})],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }