const assistanceServiceTypes = {
  AsistenteServicio: 1,
  Enfermero: 2,
};

export const assistanceServiceTypeOptions = [
  {
    id: assistanceServiceTypes.AsistenteServicio,
    name: 'Asistente de Servicio',
  },
  {
    id: assistanceServiceTypes.Enfermero,
    name: 'Enfermero(a)',
  },
];

export default assistanceServiceTypes;
