export const downloadBlobToFile = (file) => {
  const byteChars = atob(file.content);
  const byteNumbers = new Array(byteChars.length);
  for (let i = 0; i < byteChars.length; i++) {
    byteNumbers[i] = byteChars.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  let blob = new Blob([byteArray], {
    type: file.contentType,
  });
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = file.filename;
  link.click();
};
