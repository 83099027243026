<template>
  <div>
    <validation-observer ref="equipmentForm">
      <div
        v-for="(question, idx) of questions"
        :key="`question-${idx}`"
        class="mb-3"
      >
        <ordered-header-card
          class="ordered-header-card__extended"
          :class="{ disabled: isReadOnly }"
          :order="idx + 1"
        >
          <div slot="content">
            {{ question.text }}
          </div>
          <div slot="actions">
            <div class="d-flex flex-column flex-md-row align-items-center my-3">
              <checkbox
                v-model="question.model.unknownDate"
                class="mb-2 mb-md-0 mr-md-4 unknown-date"
                :disabled="isReadOnly"
                text="No sé"
                @input="unknownDateChangeHandler(idx)"
              />
              <validation-provider
                v-slot="{ errors }"
                :rules="{ required: !question.model.unknownDate }"
              >
                <custom-date-picker
                  v-model="question.model.date"
                  custom-error-msg="Favor seleccione la fecha"
                  :disabled="question.model.unknownDate || isReadOnly"
                  :error="errors[0]"
                  :max-date="new Date()"
                  placeholder="DD/MM/YYYY"
                />
              </validation-provider>
            </div>
          </div>
        </ordered-header-card>
      </div>
      <div class="d-block mt-4">
        <validation-provider v-slot="{ errors }" rules="required">
          <custom-text-area
            id="comment"
            v-model="model.equipments"
            class="equipment__text-area"
            :count-classes="['d-block', 'small-counter', 'text-right']"
            custom-error-msg="Favor indique los equipos que fueron recomendados y no han sido entregados."
            :disabled="isReadOnly"
            :error="errors[0]"
            label="Indique los equipos que fueron recomendados y no han sido entregados:"
            :maxlength="3000"
            name="comment"
            show-char-count
          />
        </validation-provider>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import OrderedHeaderCard from 'ordered-header-card';
import CustomDatePicker from 'custom-date-picker';
import Checkbox from 'CheckBox';
import CustomTextArea from 'CustomTextArea';

export default {
  name: 'EquipmentService',
  components: {
    OrderedHeaderCard,
    CustomDatePicker,
    Checkbox,
    CustomTextArea,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    model: {
      id: 0,
      equipments: null,
    },
    questions: [
      {
        text:
          'Indique cuando se llevó a cabo la evaluación en asistencia tecnológica.',
        model: {},
      },
      {
        text:
          'Indique cuando solicitó la compra del equipo al Departamento de Educación.',
        model: {},
      },
    ],
  }),
  watch: {
    data: {
      immediate: true,
      handler(newVal) {
        if (newVal && Object.keys(newVal).length) {
          const equipments = newVal.serviceEquipment[0];
          this.questions[0].model = {
            unknownDate: newVal.equipmentEvaluationDate == null,
            date: newVal.equipmentEvaluationDate,
          };
          this.questions[1].model = {
            unknownDate: newVal.equipmentRequestDate == null,
            date: newVal.equipmentRequestDate,
          };
          this.model = {
            id: equipments.id,
            equipments: equipments.equipmentName,
          };
        }
      },
    },
  },
  methods: {
    unknownDateChangeHandler(questionIdx) {
      const question = this.questions[questionIdx];
      if (question && question.model) question.model.date = null;
    },
    getModel() {
      return {
        equipmentEvaluationDate: this.questions[0].model.date,
        equipmentRequestDate: this.questions[1].model.date,
        serviceEquipment: [
          { id: this.model.id, equipmentName: this.model.equipments || '' },
        ],
      };
    },
    validateService() {
      return this.$refs.equipmentForm.validate();
    },
  },
};
</script>

<style scoped lang="scss">
  .unknown-date ::v-deep {
    label {
      width: 100px;
    }
  }
</style>