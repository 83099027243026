<template>
  <div>
    <validation-observer ref="barrierForm">
      <div
        v-for="(question, idx) of questions"
        :key="`question-${idx}`"
        class="mb-3"
      >
        <ordered-header-card
          v-if="showChildQuestion(question.id)"
          class="ordered-header-card__extended"
          :class="{ disabled: isReadOnly }"
          :order="idx + 1"
        >
          <div slot="content">
            {{ question.text }}
          </div>
          <div slot="actions">
            <div class="d-flex align-items-center">
              <validation-provider
                v-if="question.radioOptions != null"
                v-slot="{ errors }"
                :rules="{ required: question.model.radioAnswer == null }"
              >
                <custom-radio
                  :change-event="
                    (item) => {
                      onChangeYesNo(item, idx);
                    }
                  "
                  class="custom-question__extended mt-4"
                  :class="{ disabled: isReadOnly }"
                  custom-error-msg="Favor seleccione si o no."
                  :disabled="isReadOnly"
                  :error="!!errors[0]"
                  is-question-list
                  :name="`barrierQuestion-${idx}`"
                  :options="question.radioOptions"
                />
              </validation-provider>
              <validation-provider v-else v-slot="{ errors }" rules="required">
                <custom-date-picker
                  v-model="question.model.date"
                  class-container="py-3"
                  custom-error-msg="Favor seleccione la fecha"
                  :disabled="isReadOnly"
                  :error="errors[0]"
                  :max-date="new Date()"
                  placeholder="DD/MM/YYYY"
                />
              </validation-provider>
            </div>
          </div>
        </ordered-header-card>
      </div>
      <div v-if="showChildQuestion(3)" class="d-block mt-4">
        <validation-provider v-slot="{ errors }" rules="required">
          <custom-text-area
            id="comment"
            v-model="model.serviceDescription"
            class="equipment__text-area"
            :count-classes="['d-block', 'small-counter']"
            custom-error-msg="Favor escriba las barreras arquitectónica que solicita ser removida."
            :disabled="isReadOnly"
            :error="errors[0]"
            label="¿Qué tipo de barrera arquitectónica solicita sea removida?"
            :maxlength="3000"
            name="comment"
            show-char-count
          />
        </validation-provider>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import OrderedHeaderCard from 'ordered-header-card';
import CustomDatePicker from 'custom-date-picker';
import CustomTextArea from 'CustomTextArea';
import CustomRadio from 'custom-radio';
import radioOptions from '@/views/students/provisional-remedy/constants/radioOptions';

export default {
  name: 'BarrierService',
  components: {
    OrderedHeaderCard,
    CustomDatePicker,
    CustomTextArea,
    CustomRadio,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    model: {
      serviceDescription: null,
    },
    questions: [
      {
        id: 1,
        text: '¿Se solicitó la remoción de barreras arquitectónicas?',
        model: {},
        radioOptions: [
          {
            id: 1,
            name: 'question1',
            label: 'Sí',
            check: false,
          },
          {
            id: 2,
            name: 'question2',
            label: 'No',
            check: false,
          },
        ],
        childQuestionsId: [2, 3],
      },
      {
        id: 2,
        text: 'Si conoce, indique la fecha de solicitud',
        model: {},
      },
    ],
  }),
  computed: {
    showChildQuestion() {
      return (questionId) => {
        const fatherQuestion = this.questions.find((q) =>
          q.childQuestionsId?.includes(questionId)
        );
        return fatherQuestion
          ? fatherQuestion.model.radioAnswer == radioOptions.Yes
          : true;
      };
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal) {
        if (newVal && Object.keys(newVal).length) {
          this.questions[0] = {
            ...this.questions[0],
            radioOptions: this.questions[0].radioOptions.map((o) => ({
              ...o,
              check: o.id == newVal.serviceRequestedAnswerId,
            })),
            model: {
              radioAnswer: newVal.serviceRequestedAnswerId,
            },
          };
          this.model.serviceDescription = newVal.serviceDescription;
          this.questions[1].model.date = newVal.serviceRequestedDate;
        }
      },
    },
  },
  methods: {
    onChangeYesNo(answer, idx) {
      this.questions = this.questions.map((q, i) => ({
        ...q,
        model: i == idx ? { radioAnswer: answer.id } : q.model,
        radioOptions: q.radioOptions?.map((o) => ({
          ...o,
          check: o.id == answer.id,
        })),
      }));
    },
    getModel() {
      const params =
        this.questions[0].model.radioAnswer == radioOptions.Yes
          ? {
              serviceRequestedDate: this.questions[1].model.date,
              serviceRequestedAnswerId: this.questions[0].model.radioAnswer,
              serviceDescription: this.model.serviceDescription,
            }
          : {
              serviceRequestedAnswerId: this.questions[0].model.radioAnswer,
            };
      return params;
    },
    validateService() {
      return this.$refs.barrierForm.validate();
    },
  },
};
</script>
