<template>
  <div>
    <validation-observer ref="assistantForm">
      <div v-if="!isReadOnly" class="row mx-0">
        <div class="col-12 col-md mb-2 mb-md-0 border-right">
          <div class="color-gray font-16 font-weight-normal mb-2">
            Tipo de Asistente:
          </div>
          <custom-drop-down
            v-model="model.assistanceType"
            :clearable="false"
            fieldkey="id"
            fieldname="name"
            :options="getAvailableAssistanceTypes"
            placeholder="Seleccione"
          />
          <div class="text-right">
            <action-button
              i="fas fa-file-plus"
              text="Añadir"
              variant="success mt-3 pl-3 pr-3 btn-block-xs-only"
              @click="addItem(model.assistanceType)"
            />
          </div>
        </div>
        <div class="col">
          <div class="color-gray font-16 font-weight-normal mb-2">
            Asistente(s) Seleccionado(s):
          </div>
          <div class="d-flex flex-wrap">
            <div
              v-for="(item, index) in model.selectedAssistanceTypes"
              :key="`row-${index}`"
              class="chip"
            >
              <span class="mr-2">{{ item.name }}</span>
              <em
                class="far fa-lg fa-times-circle color-red cursor-pointer"
                @click="removeItem(index)"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="hasNurseType" class="row mx-0 mt-4">
        <div class="col-12">
          <span class="bg-secondary-15 p-2 color-white badge-radius">
            Enfermero(a)
          </span>

          <div class="mt-3">
            <validation-provider v-slot="{ errors }" rules="required">
              <div class="color-gray font-14 font-weight-normal">
                Fecha de comienzo:
              </div>
              <custom-date-picker
                v-if="!isReadOnly"
                v-model="model.recommendedDate"
                custom-error-msg="Favor selecciona la fecha de comienzo."
                :error="errors[0]"
                placeholder="DD/MM/YYYY"
              />
              <div v-else class="color-black font-16 font-weight-normal">
                {{ model.recommendedDate | formatDate }}
              </div>
            </validation-provider>
          </div>
          <div class="mt-3">
            <validation-provider v-slot="{ errors }" rules="required">
              <div class="color-gray font-14 font-weight-normal">
                ¿Quiénes recomiendan el servicio de enfermería?:
              </div>
              <custom-text-area
                v-if="!isReadOnly"
                v-model="model.person"
                class="equipment__text-area"
                :count-classes="['d-block', 'small-counter', 'text-right']"
                custom-error-msg="Favor escriba quienes recomiendan el servicio de enfermería."
                :error="errors[0]"
                :maxlength="3000"
                show-char-count
              />
              <div v-else class="color-black font-16 font-weight-normal">
                {{ model.person }}
              </div>
            </validation-provider>
          </div>
          <div class="mt-3">
            <validation-provider v-slot="{ errors }" rules="required">
              <div class="color-gray font-14 font-weight-normal">
                Servicios que ofrecerá el(la) enfermero(a):
              </div>
              <custom-text-area
                v-if="!isReadOnly"
                v-model="model.descriptionService"
                class="equipment__text-area"
                :count-classes="['d-block', 'small-counter', 'text-right']"
                custom-error-msg="Favor escriba el(los) servicios(s) que ofrecerá el(la) enfermero(a)."
                :error="errors[0]"
                :maxlength="3000"
                show-char-count
              />
              <div v-else class="color-black font-16 font-weight-normal">
                {{ model.descriptionService }}
              </div>
            </validation-provider>
          </div>
        </div>
      </div>
      <div v-if="hasAssistanceService" class="row mx-0 mt-5">
        <div class="col-12">
          <span class="bg-secondary-15 p-2 badge-radius color-white">
            Asistente de Servicio
          </span>
          <div
            v-for="(question, idx) of questions"
            :key="`question-${idx}`"
            class="mb-3 mt-4"
          >
            <ordered-header-card
              v-if="question.id === 1 || hasCompletedAssistanceServiceRequest"
              class="ordered-header-card__extended"
              :class="{ disabled: isReadOnly }"
              :order="idx + 1"
            >
              <div slot="content">
                <div class="py-3">{{ question.text }}</div>
              </div>
              <div slot="actions">
                <div class="d-flex flex-column flex-md-row align-items-center">
                  <div v-if="question.answerOptions">
                    <validation-provider v-slot="{ errors }" rules="required">
                      <custom-radio
                        v-model="question.model.selected"
                        :change-event="
                          (event) => {
                            onChangeQuestion(event, question);
                          }
                        "
                        class="custom-question__extended"
                        :class="{ disabled: isReadOnly }"
                        :custom-error-msg="question.validationMessage"
                        :disabled="isReadOnly"
                        :error="errors[0]"
                        is-question-list
                        name="rbCompensatory"
                        :options="question.answerOptions"
                      />
                    </validation-provider>
                  </div>
                  <div v-if="hasCheckCompleteRequest(question)" class="mb-3">
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="{ required: !question.model.hasDate }"
                    >
                      <custom-date-picker
                        v-model="model.serviceRequestedDate"
                        custom-error-msg="Favor seleccione la fecha"
                        :disabled="question.model.hasDate || isReadOnly"
                        :error="errors[0]"
                        :max-date="new Date()"
                        placeholder="DD/MM/YYYY"
                      />
                    </validation-provider>
                  </div>
                </div>
              </div>
            </ordered-header-card>
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import CustomDropDown from 'custom-drop-down';
import ActionButton from 'ActionButton';
import CustomDatePicker from 'custom-date-picker';
import OrderedHeaderCard from 'ordered-header-card';
import CustomRadio from 'custom-radio';
import CustomTextArea from 'CustomTextArea';
import assistanceServiceTypes, {
  assistanceServiceTypeOptions,
} from '@/utils/constants/rp/assistanceServiceTypes.js';

export default {
  name: 'Assistant',
  components: {
    CustomDropDown,
    ActionButton,
    CustomDatePicker,
    OrderedHeaderCard,
    CustomRadio,
    CustomTextArea,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal) {
        if (newVal && Object.keys(newVal).length) {
          this.model = {
            ...this.model,
            ...newVal,
            selectedAssistanceTypes: [
              ...(newVal.assistanceServiceFlag
                ? [{ ...assistanceServiceTypeOptions[0] }]
                : []),
              ...(newVal.nurseServiceFlag
                ? [{ ...assistanceServiceTypeOptions[1] }]
                : []),
            ],
            recommendedDate: newVal.personsRecommendedNurse[0]?.recommendedDate,
            person: newVal.personsRecommendedNurse[0]?.person,
            descriptionService: newVal.nurseServices[0]?.descriptionService,
          };

          this.questions = this.questions.map((item, i) => {
            //assistance service
            if (i === 0) {
              item.model = {
                selected: newVal.serviceRequestedAnswerId,
              };
              (
                item.answerOptions.find(
                  (x) => x.id === newVal.serviceRequestedAnswerId
                ) || {}
              ).check = true;
            }

            //transportationFlag
            if (i === 2) {
              item.model = {
                selected: item.id,
              };
              if (newVal.transportationFlag) {
                item.answerOptions[0].check = true;
              } else {
                item.answerOptions[1].check = true;
              }
            }
            return item;
          });
        }
      },
    },
  },
  data: () => ({
    model: {
      assistanceType: null,
      selectedAssistanceTypes: [],
      person: null,
      descriptionService: null,
    },
    questions: [
      {
        id: 1,
        text: '¿Se completó la solicitud de Asistente de Servicio?',
        model: {},
        validationMessage: 'Favor seleccione si, no ó no sé.',
        answerOptions: [
          {
            id: 1,
            label: 'SI',
            check: null,
            disabled: null,
          },
          {
            id: 2,
            label: 'NO',
            check: null,
            disabled: null,
          },
          {
            id: 3,
            label: 'NO SÉ',
            check: null,
            disabled: null,
          },
        ],
      },
      {
        id: 2,
        text: 'Si conoce, indique la fecha de solicitud',
        model: {},
      },
      {
        id: 3,
        text: '¿Es para servicio de transportación?',
        model: {},
        validationMessage: 'Favor seleccione si o no.',
        answerOptions: [
          {
            id: 1,
            label: 'SI',
            check: null,
            disabled: null,
          },
          {
            id: 2,
            label: 'NO',
            check: null,
            disabled: null,
          },
        ],
      },
    ],
  }),
  computed: {
    getAvailableAssistanceTypes() {
      const addedOptions = this.model.selectedAssistanceTypes.map((x) => x.id);
      return assistanceServiceTypeOptions.filter(
        (x) => !addedOptions.includes(x.id)
      );
    },
    hasNurseType() {
      return this.model.selectedAssistanceTypes.some(
        (x) => x.id === assistanceServiceTypes.Enfermero
      );
    },
    hasAssistanceService() {
      return this.model.selectedAssistanceTypes.some(
        (x) => x.id === assistanceServiceTypes.AsistenteServicio
      );
    },
    hasCheckCompleteRequest() {
      return (question) =>
        question.id === 2 && this.questions[0].answerOptions[0].check;
    },
    hasCompletedAssistanceServiceRequest() {
      return this.questions[0].answerOptions[0].check;
    },
  },
  methods: {
    addItem(item) {
      if (item?.id) {
        this.model = {
          ...this.model,
          assistanceType: null,
          selectedAssistanceTypes: [
            ...this.model.selectedAssistanceTypes,
            { ...item },
          ],
        };
      }
    },
    removeItem(index) {
      this.model.selectedAssistanceTypes.splice(index, 1);
    },
    getModel() {
      return {
        assistanceServiceFlag: this.hasAssistanceService,
        nurseServiceFlag: this.hasNurseType,
        serviceRequestedDate: this.model.serviceRequestedDate,
        serviceRequestedAnswerId: this.questions[0].answerOptions.find(
          (x) => x.check
        )?.id,
        transportationFlag: this.questions[2].answerOptions[0].check ?? false,
        personsRecommendedNurse: this.hasNurseType
          ? [
              {
                id: this.model.personsRecommendedNurse?.id,
                person: this.model.person || '',
                recommendedDate: this.model.recommendedDate,
              },
            ]
          : [],
        nurseServices: this.hasNurseType
          ? [
              {
                id: this.model.nurseServices?.id,
                descriptionService: this.model.descriptionService || '',
              },
            ]
          : [],
      };
    },
    validateService() {
      return this.$refs.assistantForm.validate();
    },
    async onChangeQuestion(event, question) {
      question.model.selected = event.id;
      if (question.id === 1) {
        this.model.serviceRequestedDate = null;
      }
      await this.$forceUpdate();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/variables/colors.scss';

.chip {
  border: 1px solid #b7c4bd;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 2px 4px 2px 14px;
  margin: 0 0.5rem 0.5rem 0.5rem;
}
.badge-radius {
  border-radius: 100px !important;
}
</style>
