<template>
  <custom-callout
    class-main="mt-3 rounded"
    :controlled="true"
    :opened="data.isOpen"
    @click:open="closeCallout()"
  >
    <div class="row py-2 mr-md-0 align-items-center">
      <div
        class="col-12 col-md-2 pl-md-0 py-2 border-right border-right--variant d-flex justify-content-center"
      >
        <div class="d-flex flex-column font-weight-bold font-16">
          {{ data.requestNumber }}
          <span class="font-weight-normal color-gray font-14">
            <i :class="`fas fa-circle mr-1 font-12 color-${statusColor}`" />
            {{ data.statusName }}
          </span>
        </div>
      </div>
      <div class="col-12 col-md-8 row mx-0">
        <div class="col-6 col-md-3 mt-3 mt-md-0">
          <span class="label">Fecha de Solicitud:</span>
          {{ data.requestDate | formatDate }}
        </div>
        <div class="col-6 col-md-3 mt-3 mt-md-0">
          <span class="label">Servicio:</span>
          {{ data.serviceName | empty }}
        </div>
        <div class="col-6 col-md-4 mt-3 mt-md-0">
          <span class="label">Determinaci&oacute;n:</span>
          {{ determinationStatusName }}

          <div v-if="isDeterminationReason">
            <div class="mb-2">
              <div class="d-inline">
                <em class="fa fa-bell-exclamation color-alert" />
              </div>
              <p class="text-left d-inline mb-2 font-14 color-alert">
                Referido devuelto a la SAEE por falta de contratación.
              </p>
            </div>
          </div>
          <div v-if="data.isReconsiderationFlag">
            <div class="mb-2">
              <div class="d-inline">
                <em class="fa fa-bell-exclamation color-alert" />
              </div>
              <p class="text-left d-inline mb-2 font-14 color-alert">
                Solicitud en proceso de Reconsideración
              </p>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2 mt-3 mt-md-0">
          <span class="label">Fecha de Determinaci&oacute;n:</span>
          {{ data.determinationDate | formatDate | empty }}
        </div>
      </div>
      <div class="col-12 col-md-2 mt-3 mt-md-0 text-right">
        <drop-down :items="actionItems" />
      </div>
    </div>
    <template #edition>
      <div
        class="p-2 mt-1"
        :class="!showRequestReconsideration ? 'bg-gray' : 'request'"
      >
        <div
          v-if="!showRequestReconsideration"
          class="bg-white rounded p-3 px-5"
        >
          <contract-history
            v-if="
              data.viewToDisplay ===
              requestHistoryViewDisplayEnum.CONTRACT_HISTORY
            "
            :items="contractData.data"
            @on-download-contract="$emit('on-download-contract', $event)"
          />
          <pagination
            v-if="contractData.totalItemCount > contractDefaultPageSize"
            :data="contractData"
            show-text
            @pagination-go-page="$emit('on-change-contract-page', $event)"
          />
        </div>
        <div v-if="showRequestReconsideration" class="request">
          <div class="mt-3 ml-3 mb-4">
            <icon-view
              class="icon-view-custom"
              :has-border="false"
              icon="fas fa-reply-all color-yellow'"
              icon-size="1rem"
              title="Solicitud de reconsideración"
              variant="custom-icon-view"
            />

            <custom-text-area
              id="comment"
              v-model="comment"
              class="mt-4 mr-3"
              label="Comentario:"
              :maxlength="3000"
              name="comment"
              show-char-count
            />

            <div
              class="row border-radius--light pb-3 pt-1"
              style="border-radius: 0px 0px 5px 5px"
            >
              <div
                class="col-12 col-md-2 offset-md-10 pl-3 pr-4 d-flex align-items-end justify-content-end"
              >
                <action-button
                  :i="`fas fa-times-circle`"
                  :responsive="true"
                  variant="outline-danger m-1"
                  @click="cancel()"
                >
                  Cancelar
                </action-button>
                <action-button
                  :i="`fas fa-save`"
                  :responsive="true"
                  variant="success m-1"
                  @click="submit()"
                >
                  Guardar
                </action-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </custom-callout>
</template>

<script>
import CustomCallout from 'custom-callout';
import DropDown from 'dropdown';
import Pagination from 'pagination';
import { ContractHistory } from './';
import requestHistoryViewDisplay from '../constants/requestHistoryViewDisplay';
import rpServiceStatuses from '@/utils/constants/rp/rpServiceStatuses.js';
import requestStatuses from '@/utils/constants/provisional-remedy/requestStatus.js';
import IconView from 'icon-view';
import CustomTextArea from 'CustomTextArea';
import ActionButton from 'ActionButton';
import DETERMINATION_REASON from '@/utils/constants/determinationReason';
import DETERMINATION_STATUS from '@/utils/constants/determinationStatus';
export default {
  name: 'ResultRow',
  components: {
    CustomCallout,
    DropDown,
    Pagination,
    ContractHistory,
    IconView,
    CustomTextArea,
    ActionButton,
  },
  data() {
    return {
      showRequestReconsideration: false,
      comment: null,
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    statusColor: {
      type: String,
      default: () => '',
    },
    contractDefaultPageSize: {
      type: Number,
      default: () => 10,
    },
  },
  computed: {
    isDeterminationReason() {
      return (
        DETERMINATION_REASON.REFERIDO_DEVUELTA_SAEE ==
        this.data.determinationReasonId
      );
    },
    determinationStatusName() {
      if (
        this.data.isReconsiderationFlag &&
        this.data.determinationStatusId === DETERMINATION_STATUS.NUEVO
      ) {
        return '-';
      } else {
        return this.data.determinationStatusName ?? '-';
      }
    },
    actionItems() {
      return [
        {
          label: 'Entrar',
          icon: 'fas fa-file-import color-green',
          border: 'bottom',
          click: () =>
            [requestStatuses.Submitted, requestStatuses.Closed].includes(
              this.data?.statusId
            )
              ? this.$router.push({
                  name: 'provisional-remedy-details',
                  params: {
                    requestId: this.data.requestId,
                  },
                })
              : this.$router.push({
                  name: 'provisional-remedy-request',
                  params: {
                    requestId: this.data.requestId,
                  },
                }),
        },
        {
          label: 'Imprimir Solicitud',
          icon: 'fas fa-print color-green',
          click: () => this.$emit('on-download-request-report'),
        },
        {
          label: 'Imprimir Carta de Aprobación',
          icon: 'fas fa-print color-green',
          border: 'bottom',
          click: () => this.$emit('on-download-approvalletter-report'),
          visible: [
            rpServiceStatuses.Aprobado,
            rpServiceStatuses.Objetado,
          ].includes(this.data?.determinationStatusId),
        },
        {
          label: 'Ver Historial de Contratos',
          icon: 'fas fa-history color-green',
          click: () => this.openCLoseHistory(),
        },
        {
          label: 'Eliminar Solicitud',
          icon: 'fas fa-times-circle color-danger',
          visible: [requestStatuses.InProcess].includes(this.data?.statusId),
          click: () => this.$emit('on-delete-service'),
        },
        {
          label: 'Solicitar Reconsideración',
          icon: 'fas fa-reply-all gold-orange',
          visible: this.isDeterminationReason,
          click: () => {
            this.showsection();
          },
        },
      ];
    },
    requestHistoryViewDisplayEnum() {
      return requestHistoryViewDisplay;
    },
    contractData() {
      return this.data?.contract ?? {};
    },
  },
  methods: {
    showsection() {
      this.showRequestReconsideration = true;

      this.$emit('on-open-close', false);
    },
    closeCallout() {
      this.showRequestReconsideration = this.data.isOpen;
      this.$emit('on-open-close', this.data.isOpen);
    },
    openCLoseHistory() {
      this.showRequestReconsideration = false;
      this.$emit('on-open-contract');
    },
    cancel() {
      this.$emit('on-open-close', true);
    },
    async submit() {
      this.$emit('on-request-reconsideration', {
        requestId: this.data.requestId,
        requestServiceId: this.data.requestServiceId,
        requestNumber: this.data.requestNumber,
        comment: this.comment,
      });
      this.comment = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';

.label {
  display: block;
  font-size: 0.875rem;
  color: map-get($colors, 'gray');
}

@media only screen and (max-width: 768px) {
  .border-right--variant {
    border-right: unset !important;
  }
}
.request {
  background-color: #fafafa;
}
.icon-view-custom {
  ::v-deep em {
    color: #f8b539;
  }
}
.color-custom {
  color: #f8b539;
}
.color-alert {
  color: #af6707;
}
</style>
