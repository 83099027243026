import { http } from '@/core/api/';

const URL = 'api/rp';

export const getRemedyFilters = () => http.get(`${URL}/initfilters`);

export const searchRemedyRequests = (params, pageIndex, pageSize) =>
  http.post(
    `${URL}/searchrequest`,
    { ...params },
    {
      params: {
        pageIndex,
        pageSize,
      },
    }
  );

export const getRequestInfo = (requestId, studentSieId) =>
  http.get(`${URL}/requestinfo`, {
    params: {
      requestId,
      studentSieId,
    },
  });

export const saveRequest = (params) => http.post(`${URL}/saverequest`, params);

export const removeRequest = (requestId) =>
  http.delete(`${URL}/request/${requestId}`);

export const getContracts = (id, pageIndex, pageSize) =>
  http.get(`${URL}/request/contracts/${id}`, {
    params: {
      pageIndex,
      pageSize,
    },
  });

export const getContractDocument = (id) =>
  http.get(`${URL}/request/contract/document/${id}`);

export const saveOrUpdateServices = (params) =>
  http.post(`${URL}/saveorupdateservices`, params);

export const getRequestServices = (requestId) =>
  http.get(`${URL}/requestservices/${requestId}`);

export const getRequestReport = (id) => http.get(`${URL}/request/report/${id}`);

export const getApprovalLetterReport = (id) =>
  http.get(`${URL}/request/report/approvalletter/${id}`);

export const getTherapyServiceCatalog = () =>
  http.get(`${URL}/therapyservicecatalog`);

export const removeRequestService = (requestId, requestServiceId) =>
  http.delete(`${URL}/removerequestservice/${requestId}/`, {
    params: {
      requestServiceId,
    },
  });

export const finishRequest = (params) =>
  http.post(`${URL}/finishrequest`, params);

export const validateUniqueService = (requestId, requestServiceId) =>
  http.get(
    `${URL}/requestservice/validunique/${requestId}/${requestServiceId}`
  );

export const getServiceTypes = () => http.get(`${URL}/servicetypes`);

export const getDisciplineTypes = (serviceTypeId) =>
  http.get(`${URL}/disciplinetypes/${serviceTypeId}`);

export const validateParentHasRequestsInStatus = (requestStatusId) =>
  http.get(`${URL}/parenthasrequestsinstatus/${requestStatusId}`);

export const requestServiceReconsideration = (payload) =>
  http.post(`${URL}/requestreconsideration`, payload);
