<template>
  <div>
    <validation-observer ref="therapyForm">
      <div v-if="isReadOnly" class="row mx-0 px-md-4">
        <div class="col-12 col-md mb-3 mb-md-0">
          <label class="data-title mb-0">
            Servicio:
          </label>
          <span class="data-content d-block">
            {{ model.discipline.name }}
          </span>
        </div>
        <div v-if="model.locationType" class="col-12 col-md mb-3 mb-md-0">
          <label class="data-title mb-0">
            Lugar:
          </label>
          <span class="data-content d-block">
            {{ model.locationType.name }}
          </span>
        </div>
        <div v-if="model.frequency" class="col-12 col-md mb-3 mb-md-0">
          <label class="data-title mb-0">
            Frecuencia:
          </label>
          <span class="data-content d-block">
            {{ model.frequency.name }}
          </span>
        </div>
        <div v-if="model.duration" class="col-12 col-md mb-3 mb-md-0">
          <label class="data-title mb-0">
            Duración:
          </label>
          <span class="data-content d-block">
            {{ model.duration.name }}
          </span>
        </div>
        <div v-if="model.modality" class="col-12 col-md mb-3 mb-md-0">
          <label class="data-title mb-0">
            Modalidad:
          </label>
          <span class="data-content d-block">
            {{ model.modality.name }}
          </span>
        </div>
      </div>
      <div v-else class="row mx-0">
        <div class="col-12 col-md-4">
          <div class="color-gray font-16 font-weight-normal mb-1">
            Servicio:
          </div>
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-drop-down
              v-model="model.discipline"
              :clearable="false"
              custom-error-msg="El servicio es requerido."
              :error="errors[0]"
              fieldkey="id"
              fieldname="name"
              :options="availableDisciplines"
              placeholder="Seleccione"
            />
          </validation-provider>
        </div>
        <div class="col-12 col-md-4 mt-3 mt-md-0">
          <div class="color-gray font-16 font-weight-normal mb-1">Lugar:</div>
          <custom-drop-down
            v-model="model.locationType"
            :clearable="false"
            fieldkey="id"
            fieldname="name"
            :options="catalog.locationTypes"
            placeholder="Seleccione"
          />
        </div>
        <div class="col-12 col-md-4 mt-3 mt-md-0">
          <div class="color-gray font-16 font-weight-normal mb-1">
            Frecuencia:
          </div>
          <custom-drop-down
            v-model="model.frequency"
            :clearable="false"
            fieldkey="id"
            fieldname="name"
            :options="catalog.frequencies"
            placeholder="Seleccione"
          />
        </div>
        <div class="col-12 col-md-4 mt-3">
          <div class="color-gray font-16 font-weight-normal mb-1">
            Duración:
          </div>
          <custom-drop-down
            v-model="model.duration"
            :clearable="false"
            fieldkey="id"
            fieldname="name"
            :options="catalog.durations"
            placeholder="Seleccione"
          />
        </div>
        <div class="col-12 col-md-4 mt-3">
          <div class="color-gray font-16 font-weight-normal mb-1">
            Modalidad:
          </div>
          <custom-drop-down
            v-model="model.modality"
            :clearable="false"
            fieldkey="id"
            fieldname="name"
            :options="catalog.modalities"
            placeholder="Seleccione"
          />
        </div>
      </div>
      <div class="row mx-0 bg-white rounded mt-4 p-3">
        <div class="col-12">
          <validation-provider v-slot="{ errors }" rules="required">
            <div class="d-md-flex align-items-center border-bottom px-3 mb-4">
              <div class="flex-grow-1 color-black font-16 mb-2 mb-md-0">
                Fecha de evaluación que recomienda intervención.
              </div>
              <custom-date-picker
                v-model="model.recommendedEvaluationDate"
                class="mb-2"
                custom-error-msg="Favor seleccione la fecha."
                :disabled="isReadOnly"
                :error="errors[0]"
                placeholder="DD/MM/YYYY"
              />
            </div>
          </validation-provider>
          <validation-provider v-slot="{ errors }" rules="required">
            <div
              class="d-flex align-items-md-center align-items-end border-bottom px-3 mb-4"
            >
              <div class="flex-grow-1 color-black font-16">
                ¿Se completó el referido a la intervención recomendada?
              </div>
              <custom-radio
                v-model="model.referralOptionSelected"
                :change-event="onChangeCompleteReferralHandler"
                class="custom-question__extended"
                :class="{ disabled: isReadOnly }"
                custom-error-msg="Favor selecciona si o no."
                :disabled="isReadOnly"
                :error="Boolean(errors[0])"
                is-question-list
                name="rbReferral"
                :options="anwserReferralOptions"
              />
            </div>
          </validation-provider>
          <validation-provider
            v-if="model.referralOptionSelected"
            v-slot="{ errors }"
            rules="required"
          >
            <div class="d-md-flex align-items-center border-bottom px-3 mb-4">
              <div class="flex-grow-1 color-black font-16 mb-2 mb-md-0">
                Si conoce, indique la fecha del referido.
              </div>
              <custom-date-picker
                v-model="model.referralDate"
                class="mb-2"
                custom-error-msg="Favor seleccione la fecha."
                :disabled="isReadOnly"
                :error="errors[0]"
                placeholder="DD/MM/YYYY"
              />
            </div>
          </validation-provider>
          <validation-provider v-slot="{ errors }" rules="required">
            <div class="d-flex align-items-center border-bottom px-3 mb-4">
              <div class="flex-grow-1 color-black font-16">
                ¿Servicio de visitas compensadas?
              </div>
              <custom-radio
                v-model="model.isCompensatory"
                :change-event="onChangeCompensatoryHandler"
                class="custom-question__extended"
                :class="{ disabled: isReadOnly }"
                custom-error-msg="Favor selecciona si o no."
                :disabled="isReadOnly"
                :error="Boolean(errors[0])"
                is-question-list
                name="rbCompensatory"
                :options="anwserCompensatoryOptions"
              />
            </div>
          </validation-provider>
          <validation-provider
            v-if="model.isCompensatory"
            :rules="{ required: true, min: 1, max: 4 }"
          >
            <div
              class="d-flex flex-md-row flex-column align-items-center border-bottom px-3 mb-4"
              slot-scope="{ errors, failedRules }"
            >
              <div class="flex-grow-1 color-black font-16">
                Favor indicar la cantidad de visitas compensadas.
              </div>
              <span
                v-if="failedRules.required"
                class="mr-4 order-3 order-md-0 color-red-required font-16"
              >
                La cantidad de visitas compensadas es requerida.
              </span>
              <span
                v-if="failedRules.min || failedRules.max"
                class="mr-4 order-3 order-md-0 color-red-required font-16"
              >
                La cantidad de visitas no debe exceder los 4 caracteres.
              </span>
              <custom-input
                v-model="model.compensatoryVisitCount"
                class="mb-2 mt-2 mt-md-0 w-sm-100"
                custom-error-msg=""
                :disabled="isReadOnly"
                :error="errors[0]"
                type="number"
              />
            </div>
          </validation-provider>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import CustomDropDown from 'custom-drop-down';
import CustomDatePicker from 'custom-date-picker';
import CustomInput from 'CustomInput';
import CustomRadio from 'custom-radio';
import { getTherapyServiceCatalog as _getTherapyServiceCatalog } from '@/modules/provisional-remedy/api';

export default {
  name: 'TherapyService',
  components: {
    CustomDropDown,
    CustomDatePicker,
    CustomInput,
    CustomRadio,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    selectedTherapyDisciplines: {
      type: Array,
      default: () => [],
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal) {
        if (newVal && Object.keys(newVal).length) {
          this.model = {
            ...this.model,
            ...newVal,
            discipline: newVal.disciplineType,
            modality: newVal.serviceModality,
            referralOptionSelected: newVal.referralDate ?? false,
            isCompensatory: newVal.isCompensatory,
          };

          if (newVal.referralDate) {
            this.anwserReferralOptions[0].check = true;
          } else {
            this.anwserReferralOptions[1].check = true;
          }
          if (newVal.isCompensatory) {
            this.anwserCompensatoryOptions[0].check = true;
          } else {
            this.anwserCompensatoryOptions[1].check = true;
          }
        }
      },
    },
  },
  computed: {
    availableDisciplines() {
      return this.catalog.services?.filter(
        (d) => !this.selectedTherapyDisciplines.includes(d.id)
      );
    },
  },
  data: () => ({
    model: {
      discipline: null,
      locationType: null,
      frequency: null,
      duration: null,
      modality: null,
      referralOptionSelected: null,
      isCompensatory: null,
    },
    catalog: {},
    anwserReferralOptions: [
      {
        id: true,
        label: 'Si',
        check: null,
        disabled: null,
      },
      {
        id: false,
        label: 'No',
        check: null,
        disabled: null,
      },
    ],
    anwserCompensatoryOptions: [
      {
        id: true,
        label: 'Si',
        check: null,
        disabled: null,
      },
      {
        id: false,
        label: 'No',
      },
    ],
  }),
  async mounted() {
    if (!this.isReadOnly) await this.initCatalog();
  },
  methods: {
    async initCatalog() {
      this.catalog = (await _getTherapyServiceCatalog()).data;
    },
    getModel(sendCatalogOption = false) {
      const selection = sendCatalogOption
        ? {
            disciplineType: this.model.discipline,
            serviceModality: this.model.modality,
            locationType: this.model.locationType,
            frequency: this.model.frequency,
            duration: this.model.duration,
          }
        : {
            disciplineTypeId: this.model.discipline?.id,
            locationTypeId: this.model.locationType?.id,
            serviceModalityTypeId: this.model.modality?.id,
            FrequencyId: this.model.frequency?.id,
            DurationId: this.model.duration?.id,
          };
      return {
        ...selection,
        recommendedEvaluationDate: this.model.recommendedEvaluationDate,
        referralDate: this.model.referralDate,
        isCompensatory: this.model.isCompensatory,
        compensatoryVisitCount: +this.model.compensatoryVisitCount,
      };
    },
    validateService() {
      return this.$refs.therapyForm.validate();
    },
    onChangeCompleteReferralHandler(value) {
      this.model.referralOptionSelected = value?.id;
      this.model.referralDate = null;
    },
    onChangeCompensatoryHandler(value) {
      this.model.isCompensatory = value?.id;
      this.model.compensatoryVisitCount = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100%;
  }
}
</style>
