<template>
  <div class="p-4 bg-secondary-3 mt-3 rounded">
    <i
      aria-controls="statusBlock"
      aria-expanded="false"
      class="fas fa-lg mr-3"
      :class="{
        'fa-chevron-circle-right color-dark-gray': collapsed,
        'fa-chevron-circle-down color-green': !collapsed,
      }"
      data-toggle="collapse"
      href="#statusBlock"
      role="button"
      @click="
        () => {
          collapsed = !collapsed;
        }
      "
    />
    <span class="color-dark-gray font-weight-bold">
      <i class="fas fa-list-ul color-orange mr-2" />
      Leyenda de Estatus
    </span>
    <div id="statusBlock" class="collapse">
      <div
        v-for="(status, idx) in statusList"
        :key="`status-list-${idx}`"
        class="row mx-0 rounded bg-secondary-9 px-3 py-2 mt-3"
      >
        <div
          class="col-12 col-md-3 pl-md-5 border-responsive d-flex align-items-center justify-content-center justify-content-md-start py-md-0 py-4"
        >
          <span class="color-dark-gray font-weight-bold">
            {{ status.title }}
          </span>
        </div>
        <div class="col-12 col-md-9 py-md-3 pb-3 pt-4 pl-md-5">
          <div
            v-for="(item, index) in status.list"
            :key="`status-item-${index}`"
            class="d-block mb-2"
          >
            <div
              class="font-14 d-flex flex-column align-items-center flex-sm-row"
            >
              <div class="color-dark-gray font-weight-bold leyend-title-size">
                <i
                  v-if="item.color"
                  class="fas fa-circle mr-2"
                  :class="`color-${item.color}`"
                />
                {{ item.name }}
              </div>
              <span class="color-gray"> - {{ item.description }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusLeyend",
  props: {
    statusList: {
      type: Array,
      required: true,
    },
  },
  data: () => ({ collapsed: true }),
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/v2/brand_variables.scss";

.border-responsive {
  border-right: 1px solid $gray-400;
}

.leyend-title-size {
  width: 110px;
}

@media only screen and (max-width: 768px) {
  .border-responsive {
    border-right: unset;
    border-bottom: 1px solid $gray-400;
  }
}
</style>
