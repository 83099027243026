const transportationServiceTypes = {
  ServicioEducativo: 1,
  ServicioIntervencion: 2,
};

export const transportationServiceTypeOptions = [
  {
    id: transportationServiceTypes.ServicioEducativo,
    name: 'Servicio Educativo (hogar a escuela/escuela a hogar)',
  },
  {
    id: transportationServiceTypes.ServicioIntervencion,
    name: 'Servicio de Intervención ',
  },
];

export default transportationServiceTypes;
