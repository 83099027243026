<template>
  <div>
    <validation-observer ref="transportationForm">
      <div class="row mx-0 bg-white rounded p-3">
        <div v-if="!isReadOnly" class="col-12 col-md mb-2 mb-md-0 border-right">
          <validation-observer ref="transportationAddForm">
            <validation-provider
              v-slot="{ errors }"
              :rules="{
                required: model.selectedTransportationServiceTypes.length == 0,
              }"
            >
              <div class="color-gray font-16 font-weight-normal mb-2">
                Servicio de Transportación:
              </div>
              <custom-drop-down
                v-model="model.transportationServiceType"
                :clearable="false"
                custom-error-msg="Favor seleccione al menos un servicio."
                :error="transportationServiceTypeError(errors[0])"
                fieldkey="id"
                fieldname="name"
                :options="getAvailableTransportationServiceTypes"
                placeholder="Seleccione"
              />
            </validation-provider>
            <div class="text-right">
              <action-button
                i="fas fa-file-plus"
                text="Añadir"
                variant="success mt-3 pl-3 pr-3 btn-block-xs-only"
                @click="addItem(model.transportationServiceType)"
              />
            </div>
          </validation-observer>
        </div>
        <div class="col">
          <div class="color-gray font-16 font-weight-normal mb-2">
            Servicio(s) de Transportación Seleccionado(s):
          </div>
          <div class="d-flex flex-wrap">
            <div
              v-for="(item, index) in model.selectedTransportationServiceTypes"
              :key="`row-${index}`"
              class="chip"
            >
              <span class="mr-2">{{ item.name }}</span>
              <em
                v-if="!isReadOnly"
                class="far fa-lg fa-times-circle color-red cursor-pointer"
                @click="removeItem(index)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-0 bg-white rounded p-3 mt-3">
        <div class="col-12">
          <validation-provider v-slot="{ errors }" rules="required">
            <div class="d-md-flex align-items-center border-bottom px-3 mb-4">
              <div class="flex-grow-1 color-black font-16">
                ¿Cuándo se completó la solicitud de beca de transportación?
              </div>
              <custom-date-picker
                v-model="model.transportationGrantRequestedDate"
                class="mb-2 mt-2 mt-md-0"
                custom-error-msg="Favor seleccione la fecha."
                :disabled="isReadOnly"
                :error="errors[0]"
                placeholder="DD/MM/YYYY"
              />
            </div>
          </validation-provider>
          <validation-provider v-slot="{ errors }" rules="required">
            <div class="d-md-flex align-items-center border-bottom px-3 mb-4">
              <div class="flex-grow-1 color-black font-16">
                ¿Cuándo se completó la solicitud de transportación?
              </div>
              <custom-date-picker
                v-model="model.transportationRequestedDate"
                class="mb-2 mt-2 mt-md-0"
                custom-error-msg="Favor seleccione la fecha."
                :disabled="isReadOnly"
                :error="errors[0]"
                placeholder="DD/MM/YYYY"
              />
            </div>
          </validation-provider>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import CustomDropDown from 'custom-drop-down';
import ActionButton from 'ActionButton';
import CustomDatePicker from 'custom-date-picker';
import transportationServiceTypes, {
  transportationServiceTypeOptions,
} from '@/utils/constants/rp/transportationServiceTypes';
export default {
  name: 'TransportationService',
  components: {
    CustomDropDown,
    ActionButton,
    CustomDatePicker,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal) {
        if (newVal && Object.keys(newVal).length) {
          this.model = {
            ...this.model,
            ...newVal,
            selectedTransportationServiceTypes: [
              ...(newVal.educationalServiceFlag
                ? [{ ...transportationServiceTypeOptions[0] }]
                : []),
              ...(newVal.therapyServiceFlag
                ? [{ ...transportationServiceTypeOptions[1] }]
                : []),
            ],
          };
        }
      },
    },
  },
  data: () => ({
    model: {
      transportationServiceType: null,
      selectedTransportationServiceTypes: [],
      transportationGrantRequestedDate: null,
      transportationRequestedDate: null,
    },
    requireService: null,
  }),
  computed: {
    transportationServiceTypeError() {
      return (error) => (error || this.requireService ? 'error' : null);
    },
    getAvailableTransportationServiceTypes() {
      const addedOptions = this.model.selectedTransportationServiceTypes.map(
        (x) => x.id
      );
      return transportationServiceTypeOptions.filter(
        (x) => !addedOptions.includes(x.id)
      );
    },
  },
  methods: {
    async addItem(item) {
      const isValid = await this.$refs.transportationAddForm.validate();
      if (isValid && item?.id) {
        this.requireService = null;
        this.model = {
          ...this.model,
          transportationServiceType: null,
          selectedTransportationServiceTypes: [
            ...this.model.selectedTransportationServiceTypes,
            { ...item },
          ],
        };
        await this.$refs.transportationAddForm.reset();
      }
    },
    removeItem(index) {
      this.model.selectedTransportationServiceTypes.splice(index, 1);
    },
    getModel() {
      return {
        ...this.model,
        educationalServiceFlag: this.model.selectedTransportationServiceTypes.some(
          (x) => x?.id === transportationServiceTypes.ServicioEducativo
        ),
        therapyServiceFlag: this.model.selectedTransportationServiceTypes.some(
          (x) => x?.id === transportationServiceTypes.ServicioIntervencion
        ),
      };
    },
    async validateService() {
      this.requireService =
        this.model.selectedTransportationServiceTypes.length === 0;

      const isFormValid = await this.$refs.transportationForm.validate();

      return isFormValid && !this.requireService;
    },
  },
};
</script>

<style scoped lang="scss">
.chip {
  border: 1px solid #b7c4bd;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 2px 4px 2px 14px;
  margin: 0 0.5rem 0.5rem 0.5rem;
}
.badge-radius {
  border-radius: 100px !important;
}
</style>
