<template>
  <div>
    <page-header
      :actions="headerActionsConfig"
      left-side-class="col-12 col-md-7"
      :page="headerConfig"
      right-side-class="col-12 col-md-5"
    />
    <filter-manager
      ref="filterComponent"
      v-model="filters"
      class="mb-4"
      :display-advanced="false"
      :filters="filtersConfig"
      @search="onSearchHandler"
    />
    <custom-separator class="my-4" text="Historial de Solicitudes" />
    <custom-result-not-found v-if="results.length === 0" />
    <div v-for="(row, idx) of results" :key="`result-${idx}`">
      <result-row
        :contract-default-page-size="defaultContractPageSize"
        :data="row"
        :status-color="statusColor(row.statusId)"
        @on-change-contract-page="
          onOpenContractHandler(idx, row.requestId, $event)
        "
        @on-delete-service="onDeleteServiceHandler(row)"
        @on-download-approvalletter-report="
          onDownloadApprovalLetterReportHandler(row)
        "
        @on-download-contract="onDownloadContractHandler"
        @on-download-request-report="onDownloadRequestReportHandler(row)"
        @on-open-close="onOpenCloseHandler($event, row.requestId, idx)"
        @on-open-contract="onOpenContractHandler(idx, row.requestId, 1)"
        @on-request-reconsideration="onRequestReconsideration($event)"
      />
    </div>
    <pagination
      v-if="pagination.totalItemCount > pagination.pageSize"
      :data="pagination"
      show-text
      @pagination-go-page="goToPage"
    />
    <status-leyend :status-list="statusLeyendData" />
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import FilterManager from 'FilterManager';
import Pagination from 'pagination';
import CustomSeparator from 'custom-separator';
import CustomResultNotFound from 'custom-result-not-found';
import ResultRow from '@/views/students/provisional-remedy/components/ResultRow.vue';
import Code from '@/utils/constants/provisional-remedy/requestStatus';
import statusColors from '@/utils/constants/statusColors';
import { providerDirectoryType } from '@/enums/provider-directory-type.js';
import parameters from '@/utils/constants/sysParamCodeTypes/parameters';

import {
  getRemedyFilters,
  searchRemedyRequests,
  getContracts as _getContracts,
  getContractDocument as _getContractDocument,
  getRequestReport as _getRequestReport,
  getApprovalLetterReport as _getApprovalLetterReport,
  validateUniqueService as _validateUniqueService,
  removeRequestService as _removeRequestService,
  requestServiceReconsideration as _requestServiceReconsideration,
} from '@/modules/provisional-remedy/api';
import { getByParameterName as _getByParameterName } from '@/api/sysparameter.api.js';
import requestHistoryViewDisplay from './constants/requestHistoryViewDisplay';
import { downloadBlobToFile } from '@/utils/blob';
import mixAlert from '@/mixins/alert.mixin';
import StatusLeyend from '@/views/students/provisional-remedy/components/StatusLeyend.vue';

const SERVICE_IDS_TO_EXCLUDE = [6];

export default {
  name: 'Search',
  mixins: [mixAlert],
  components: {
    PageHeader,
    FilterManager,
    Pagination,
    ResultRow,
    CustomSeparator,
    CustomResultNotFound,
    StatusLeyend,
  },
  data: () => ({
    filters: {},
    filterOptions: {
      services: [],
      statuses: [],
    },
    pagination: {
      currentPage: 1,
      totalItemCount: 0,
      pageCount: 1,
      count: 0,
      pageSize: 10,
    },
    results: [],
    statusLeyendData: [
      {
        title: 'Estatus de la Solicitud',
        list: [
          {
            color: 'green-light',
            name: 'En Proceso',
            description:
              'Solicitud ha sido guardada en sistema, pero no ha sido sometida.',
          },
          {
            color: 'yellow-light',
            name: 'Sometido',
            description:
              'Solicitud en espera de determinación por la unidad de remedio provisional.',
          },
        ],
      },
      {
        title: 'Estatus del Servicio',
        list: [
          {
            name: 'Aprobado',
            description:
              'Servicio ha sido determinado como aprobado por la unidad de remedio provisional.',
          },
          {
            name: 'Objetado',
            description:
              'Servicio ha sido determinado como objetado por la unidad de remedio provisional.',
          },
          {
            name: 'Cancelado',
            description:
              'Servicio ha sido determinado como cancelado por la unidad de remedio provisional.',
          },
        ],
      },
      {
        title: 'Estatus de Contrato',
        list: [
          {
            name: 'Firmado',
            description:
              'Contrato ha sido impreso, tiene las firmas correspondientes y ha sido sometido al sistema.',
          },
          {
            name: 'Enmendado',
            description:
              'Contrato ha sido modificado para cubrir la necesidad del estudiante. ',
          },
          {
            name: 'Cancelado',
            description: 'Contrato ha sido cancelado.',
          },
          {
            name: 'Denegado',
            description:
              'Contrato ha sido denegado, ya que la información no es la correcta y debe ser verificada.',
          },
        ],
      },
    ],
  }),
  computed: {
    headerConfig() {
      return {
        title: 'Remedio Provisional',
        icon: 'fas fa-file-contract',
        routes: ['Inicio', 'Remedio Provisional'],
      };
    },
    headerActionsConfig() {
      return [
        {
          text: 'Regresar',
          icon: 'fas fa-arrow-circle-left fa-fw',
          variant: 'outline-success',
          click: () => this.$router.push({ name: 'home' }),
        },
        {
          text: 'Directorio proveedores',
          icon: 'fas fa-link fa-fw',
          variant: 'outline-success',
          visible: this.userHasPermissions(
            'parents.provisionalremedy.browsedirectory'
          ),
          click: () => this.goProviderDirectoryView(),
        },
        {
          text: 'Crear Nueva Solicitud',
          icon: 'fas fa-file-plus',
          variant: 'success ml-0',
          click: () =>
            this.$router.push({ name: 'provisional-remedy-request' }),
        },
      ];
    },
    filtersConfig() {
      return [
        {
          name: 'service',
          component: 'CustomDropDown',
          options: this.filterOptions.services,
          placeholder: 'Seleccione Servicio',
          fieldName: 'name',
          fieldKey: 'id',
          clearable: true,
          class: 'col-12 col-md-4',
          initialValue: this.filters.service,
        },
        {
          name: 'status',
          component: 'CustomDropDown',
          options: this.filterOptions.statuses,
          placeholder: 'Seleccione estatus',
          fieldName: 'name',
          fieldKey: 'id',
          class: 'col-12 col-md-4',
          clearable: true,
        },
        {
          name: 'requestNumber',
          component: 'CustomInput',
          icon: 'fa fa-search color-orange',
          leftIcon: true,
          placeholder: 'Escriba Número de Solicitud',
          class: 'col-12 col-md-4',
        },
      ];
    },
    statusColor() {
      return (status) => {
        switch (status) {
          case Code.InProcess:
            return statusColors.GreenLight;
          case Code.Submitted:
          case Code.Closed:
            return statusColors.Yellow;
        }
      };
    },
    requestHistoryViewDisplayEnum() {
      return requestHistoryViewDisplay;
    },
    defaultContractPageSize() {
      return 5;
    },
  },
  async created() {
    await Promise.all([this.getFilters(), this.onSearchHandler()]);
  },
  methods: {
    async getFilters() {
      const { data } = await getRemedyFilters();
      this.filterOptions = {
        services: data.services.filter(
          (x) => !SERVICE_IDS_TO_EXCLUDE.includes(x.id)
        ),
        statuses: data.requestStatus,
      };
    },
    async onSearchHandler() {
      const filters = { ...this.filters };
      const params = {
        studentSieId: +this.$route.params.studentSieId,
        serviceId: filters.service?.id,
        statusId: filters.status?.id,
        requestNumber: filters.requestNumber,
      };
      await searchRemedyRequests(
        params,
        this.pagination.currentPage,
        this.pagination.pageSize
      ).then(({ data }) => {
        this.results = [
          ...data.data.map((x) => ({
            ...x,
            isOpen: false,
            statusName: x.statusId == Code.Closed ? 'Sometido' : x.statusName,
            viewToDisplay: requestHistoryViewDisplay.UNSET,
          })),
        ];
        this.pagination = {
          ...this.pagination,
          totalItemCount: data.totalItemCount,
          pageCount: data.pageCount,
          currentPage: data.currentPage,
          count: data.count,
        };
      });
    },
    async goToPage(page) {
      this.pagination.currentPage = page;
      await this.onSearchHandler();
    },
    setActiveViewDisplay(viewDisplay, index, prop) {
      this.results = this.results.map((x, idx) => ({
        ...x,
        isOpen: idx === index,
        viewToDisplay:
          idx === index ? viewDisplay : requestHistoryViewDisplay.UNSET,
        ...(idx === index ? prop : {}),
      }));
    },
    onOpenCloseHandler(isOpen, requestId, idx) {
      if (!isOpen) {
        this.onOpenContractHandler(idx, requestId, 1);
        return;
      }
      this.results = this.results.map((x) => ({
        ...x,
        isOpen: false,
      }));
    },
    async onOpenContractHandler(index, requestId, pageIndex) {
      if (requestId) {
        const contract = (
          await _getContracts(
            requestId,
            pageIndex,
            this.defaultContractPageSize
          )
        ).data;
        this.setActiveViewDisplay(
          requestHistoryViewDisplay.CONTRACT_HISTORY,
          index,
          { contract: contract }
        );
      }
    },
    async onRequestReconsideration(payload) {
      const answer = await this.ShowCancelModal(
        'Confirmación',
        `¿Está seguro que desea solicitar reconsideración para la solicitud ${payload.requestNumber}?`,
        'Sí',
        'No'
      );

      if (answer) {
        await _requestServiceReconsideration(payload)
          .then(async () => {
            this.ShowToast(
              'Confirmación',
              'La solicitud de reconsideración se ha sometido exitosamente.',
              'success'
            );
            this.onOpenCloseHandler(true, null, null); // Closing all rows
            await this.onSearchHandler();
          })
          .catch((err) => this.catchHandler(err));
      }
    },
    async onDownloadContractHandler({ contractId }) {
      if (contractId) {
        const { data: documentResult } = await _getContractDocument(
          contractId
        ).catch((err) => this.catchHandler(err));
        if (documentResult) {
          downloadBlobToFile(documentResult);
        }
      }
    },
    async onDownloadRequestReportHandler({ requestId }) {
      if (requestId) {
        const { data: documentResult } = await _getRequestReport(
          requestId
        ).catch((err) => this.catchHandler(err));

        if (documentResult) {
          downloadBlobToFile(documentResult);
        }
      }
    },
    async onDownloadApprovalLetterReportHandler({ requestId }) {
      if (requestId) {
        const { data: documentResult } = await _getApprovalLetterReport(
          requestId
        ).catch((err) => this.catchHandler(err));

        if (documentResult) {
          downloadBlobToFile(documentResult);
        }
      }
    },
    async onDeleteServiceHandler({ requestId, requestServiceId, serviceName }) {
      let messageDescription = '¿Está seguro que desea eliminar la solicitud?';

      if (requestId && requestServiceId > 0) {
        const isUnique = (
          await _validateUniqueService(requestId, requestServiceId)
        ).data;

        messageDescription = isUnique
          ? `¿Está seguro que desea eliminar la solicitud?`
          : `¿Está seguro que desea eliminar el servcio de ${serviceName}?`;

        let answer = await this.ShowCancelModal(
          'Confirmación',
          messageDescription,
          'Sí',
          'No'
        );

        if (answer) {
          await _removeRequestService(requestId, requestServiceId);
          await this.onSearchHandler();
        }
      } else {
        let answer = await this.ShowCancelModal(
          'Confirmación',
          messageDescription,
          'Sí',
          'No'
        );
        if (answer) {
          await _removeRequestService(requestId, null);
          await this.onSearchHandler();
        }
      }
    },
    catchHandler(err) {
      if (err.response != null)
        this.ShowToast(
          'Alerta',
          err.response.data.message || 'error',
          'error',
          5000
        );
    },
    async goProviderDirectoryView() {
      const { data: parameter } = await _getByParameterName(
        parameters.PublicPortalUrl
      );
      window.open(
        `${parameter.value}provider-directory/${providerDirectoryType.USPQRP}`,
        '_blank'
      );
    },
  },
};
</script>

<style></style>
