<template>
  <div class="evaluation-container">
    <div v-if="isReadOnly" class="row mx-0 px-3">
      <div class="col-12 col-md mb-3 mb-md-0">
        <label class="data-title mb-0">
          Tipo de Evaluación:
        </label>
        <span class="data-content d-block">
          {{ getLabel(model.evaluationType) }}
        </span>
      </div>
      <div v-if="isAditionalEvaluation" class="col-12 col-md-2 mb-3 mb-md-0">
        <label class="data-title mb-0">
          ¿Es trianual?
        </label>
        <span class="data-content d-block">
          {{ model.isTrianual | formatBoolean }}
        </span>
      </div>
      <div class="col-12 col-md mb-3 mb-md-0">
        <label class="data-title mb-0">
          Disciplina:
        </label>
        <span class="data-content d-block">
          {{ getLabel(model.discipline) }}
        </span>
      </div>
      <div class="col-12 col-md mb-3 mb-md-0">
        <label class="data-title mb-0">
          ¿Se completó referido a la evaluación recomendada?
        </label>
        <span class="data-content d-block">
          {{ model.referralComplete | formatBoolean }}
        </span>
      </div>
    </div>
    <validation-observer v-else ref="evaluationForm">
      <div class="row mx-0">
        <div
          class="col-12 mb-3"
          :class="isAditionalEvaluation ? 'col-md-3' : 'col-md-4'"
        >
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-drop-down
              v-model="model.evaluationType"
              :clearable="false"
              custom-error-msg="El tipo de evaluación es requerido."
              :error="errors[0]"
              fieldkey="id"
              fieldname="name"
              label="Tipo de Evaluación:"
              :options="options.evaluationTypes"
              placeholder="Seleccione"
              @input="getDisciplines"
            />
          </validation-provider>
        </div>
        <div v-if="isAditionalEvaluation" class="col-12 col-md-2 mb-3">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-radio
              v-model="model.isTrianual"
              custom-error-msg="Favor selecciona si o no."
              :error="Boolean(errors[0])"
              is-inline
              label="¿Es trianual?"
              :name="`trianual-${serviceIndex}`"
              :options="defaultRadioOptions"
            />
          </validation-provider>
        </div>
        <div
          class="col-12 mb-3"
          :class="isAditionalEvaluation ? 'col-md-3' : 'col-md-4'"
        >
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-drop-down
              v-model="model.discipline"
              :clearable="false"
              custom-error-msg="La disciplina es requerida."
              :disabled="options.disciplines === 0"
              :error="errors[0]"
              fieldkey="id"
              fieldname="name"
              label="Disciplina:"
              :options="availableDisciplines"
              placeholder="Seleccione"
            />
          </validation-provider>
        </div>
        <div class="col-12 col-md-4">
          <validation-provider v-slot="{ errors }" rules="required">
            <custom-radio
              v-model="model.referralComplete"
              custom-error-msg="Favor selecciona si o no."
              :error="Boolean(errors[0])"
              is-inline
              label="¿Se completó referido a la evaluación recomendada?"
              :name="`referralComplete-${serviceIndex}`"
              :options="defaultRadioOptions"
            />
          </validation-provider>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import CustomDropDown from 'custom-drop-down';
import CustomRadio from 'custom-radio';
import evaluationType from '@/utils/constants/provisional-remedy/evaluationType';
import {
  getServiceTypes as _getServiceTypes,
  getDisciplineTypes,
} from '@/modules/provisional-remedy/api';

export default {
  name: 'EvaluationService',
  components: {
    CustomDropDown,
    CustomRadio,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    selectedEvaluationDisciplines: {
      type: Array,
      default: () => [],
    },
    serviceIndex: {
      type: Number,
      default: () => 0,
    },
    isReadOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    model: {
      evaluationType: null,
      isTrianual: null,
      discipline: null,
      referralComplete: null,
    },
    options: {
      evaluationTypes: [],
      disciplines: [],
    },
    defaultRadioOptions: [
      {
        id: 1,
        name: 'evaluation-rbnt',
        value: true,
        label: 'Si',
        check: false,
      },
      {
        id: 2,
        name: 'evaluation-rbnt',
        value: false,
        label: 'No',
        check: false,
      },
    ],
  }),
  computed: {
    getLabel() {
      return (model) => (model ? model.name : '');
    },
    isAditionalEvaluation() {
      return (
        this.model.evaluationType?.id == evaluationType.AditionalEvaluation
      );
    },
    availableDisciplines() {
      return this.options.disciplines.filter(
        (d) => !this.selectedEvaluationDisciplines.includes(d.name)
      );
    },
  },
  watch: {
    data: {
      immediate: true,
      async handler(newVal) {
        await this.getServicesTypes();
        if (newVal && Object.keys(newVal).length) {
          this.model.evaluationType = this.options.evaluationTypes.find(
            (e) => e.id == newVal.serviceTypeCd
          );
          await this.getDisciplines();
          this.model = {
            ...this.model,
            discipline: this.options.disciplines.find(
              (d) => d.id == newVal.disciplineTypeCd
            ),
            isTrianual: newVal.triannualFlag,
            referralComplete: newVal.referralCompleteFlag,
          };
        }
      },
    },
  },
  methods: {
    async getServicesTypes() {
      if (this.options.evaluationTypes.length) return;
      const { data } = await _getServiceTypes();
      this.options.evaluationTypes = data;
    },
    async getDisciplines() {
      this.model.discipline = null;
      this.model.isTrianual = null;
      const evaluationId = this.model.evaluationType?.id;
      if (evaluationId) {
        const { data } = await getDisciplineTypes(evaluationId);
        this.options.disciplines = data;
      }
    },
    getModel() {
      return {
        serviceTypeCd: this.model.evaluationType?.id,
        disciplineTypeCd: this.model.discipline?.id,
        triannualFlag: this.model.isTrianual,
        referralCompleteFlag: this.model.referralComplete,
      };
    },
    validateService() {
      return this.$refs.evaluationForm.validate();
    },
  },
};
</script>
